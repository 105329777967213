<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveSpacePrice"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>VAT</td>
            <td>
              <div>
                <input type="text" v-model="mTaxPercentage" class="input" />
              </div>

              <div v-if="taxPercentages.length > 0" class="select">
                <select v-model="mTaxPercentage">
                  <option
                    v-for="taxPercentage in taxPercentages"
                    :key="taxPercentage"
                    :value="taxPercentage"
                    :selected="taxPercentage === taxPercentage"
                    >{{ taxPercentage }}%</option
                  >
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Price</td>
            <td>
              <div>
                <input v-model.number="mPrice" class="input" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import yieldProvider from '@/providers/yield'
import voucherProvider from '@/providers/voucher'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mPrice: 0,
      mTaxPercentage: 0,
      mYieldSettings: null,
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('voucherStore', ['voucher']),

    yieldSettings() {
      let settings = null

      if (
        this.voucher &&
        this.voucher.Prices &&
        this.voucher.Prices.length > 0
      ) {
        settings = this.voucher.Prices[0]
      }

      return settings
    },

    price() {
      let price = 0

      if (this.yieldSettings && this.yieldSettings.PriceParts) {
        price = this.yieldSettings.PriceParts[0].Price
      }

      return price
    },

    taxPercentage() {
      let taxPercentage = 0

      if (this.yieldSettings) {
        taxPercentage = this.yieldSettings.TaxPercentage
      }

      return taxPercentage
    },

    taxPercentages() {
      let result = []

      if (this.locationState) {
        result = this.locationState.TaxPercentages.map((t) => t.TaxPercentage)
      }

      return result
    },
  },

  created() {
    if (this.locationId > 0) {
      this.channelId = 0
    }

    this.mYieldSettings = JSON.parse(JSON.stringify(this.yieldSettings))
    this.mPrice = this.price ? JSON.parse(JSON.stringify(this.price)) : 0
    this.mTaxPercentage = this.taxPercentage
      ? JSON.parse(JSON.stringify(this.taxPercentage))
      : 0
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),

    saveSpacePrice() {
      if (this.yieldSettings) {
        this.updateYieldSettings()
      } else {
        this.createYieldSettings()
      }
    },

    // createYieldSettings() {
    //   let priceParts = []
    //   priceParts.push({ Part: 1, Price: this.mPrice })

    //   this.mYieldSettings = {
    //     Id: 0,
    //     LocationId: this.voucher ? 0 : this.locationId,
    //     ChannelId: this.voucher ? 0 : this.channelId,
    //     MeetingtypeId: this.meetingtypeId,
    //     SpaceId: this.spaceId,
    //     VoucherId: this.voucher.Id,
    //     TaxPercentage: this.mTaxPercentage,
    //     ValidFrom: this.$options.filters.dateObjectIsoDateString(
    //       this.voucher.ValidFrom
    //     ),
    //     Status: 'Approved',
    //     CalculationType: 'PerDayPartPerPerson',
    //     HoursPerDayPart: 1,
    //     PriceParts: priceParts,
    //     ApplyLocationUtilization: false,
    //     ApplySpaceUtilization: false,
    //     ApplyDayPercentage: false,
    //     ApplyGroupSize: false,
    //   }

    //   this.saveYieldSetting()
    // },

    createYieldSettings() {
      let criteria = {
        YieldSettingId: 0,
        LocationId: this.locationId,
        TaxPercentage: this.mTaxPercentage,
        SpacePrice: this.mPrice,
      }

      this.saveYieldSetting(criteria)
    },

    // updateYieldSettings() {
    //   let priceParts = []
    //   priceParts.push({ Part: 1, Price: this.mPrice })

    //   this.mYieldSettings.TaxPercentage = this.mTaxPercentage
    //   this.mYieldSettings.PriceParts = priceParts

    //   this.saveYieldSetting()
    // },

    updateYieldSettings() {
      let criteria = {
        YieldSettingId: this.mYieldSettings.Id,
        LocationId: this.locationId,
        TaxPercentage: this.mTaxPercentage,
        SpacePrice: this.mPrice,
      }

      this.saveYieldSetting(criteria)
    },

    saveYieldSetting(criteria) {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        voucherProvider.methods
          .saveVoucherSpacePrice(this.voucher.Id, criteria)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              self.setVoucher(response.data)

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    // saveYieldSetting() {
    //   let self = this

    //   if (!self.isSaving) {
    //     self.isSaving = true

    //     yieldProvider.methods
    //       .updateYieldSettings(self.mYieldSettings)
    //       .then((response) => {
    //         if (response.status === 200) {
    //           self.isSavingSuccess = true

    //           let prices = []
    //           prices.push(response.data)
    //           self.voucher.Prices = prices
    //           self.setVoucher(self.voucher)

    //           setTimeout(() => {
    //             self.onClickCancel()
    //           }, 1500)
    //         }
    //       })
    //       .catch((error) => {
    //         self.isSavingError = true
    //       })
    //       .finally(() => {
    //         self.isSaving = false
    //       })
    //   }
    //},
  },
}
</script>
